<template>
  <v-card :title="$t('admin.assets.lookupTypes.delete')">
    <v-card-text>{{ $t('admin.assets.lookupTypes.deleteText') }}</v-card-text>

    <v-card-text v-if="lookupValues.length">
      <v-alert type="error">{{ $t("admin.assets.lookupTypes.deleteErrorLinkedValues", lookupValues.length) }}</v-alert>

      <v-card variant="outlined" class="mt-3">
        <v-virtual-scroll max-height="400" item-height="40" :items="lookupValues">
          <template #default="{ item: lookupValue }">
            <v-list-item :key="lookupValue._id" :to="`/admin/assets/lookup-values/${lookupValue._id}`" append-icon="chevron_right">
              <template #title>{{ lookupValue.description }}</template>
              <template #subtitle>
                <span v-if="lookupValue.organization">{{ organizations.find(org => org._id === lookupValue.organization)?.description ?? lookupValue.organization }}</span>
              </template>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
      <v-btn color="error" :loading="loading" :disabled="!!lookupValues.length" @click="() => onDelete()">{{ $t("delete") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "submit"): void
  (e: "cancel"): void
}>()

const props = defineProps<{
  lookupType: LookupType
}>()
const { lookupType } = toRefs(props)

const $i18n = useI18n()

const mainStore = useMainStore()
const lookupValueStore = useLookupValueStore()
const organizationStore = useOrganizationStore()

const { data: organizations } = useLazyAsyncData('organizations', async () => {
  return await organizationStore.getAllOrganizations({ pagination: false })
}, { default: () => [] as Array<Organization> })

const { data: lookupValues } = useAsyncData(`lookup-values:${lookupType.value._id}`, async () => {
  return (await lookupValueStore.getLookupValuesByPage({ pagination: false, query: JSON.stringify({ lookupType: lookupType.value._id }) })).docs
}, { default: () => [] as Array<LookupValue> })

const loading = ref(false)

const onDelete = async () => {
  await useExplicitSave(mainStore.notify, $i18n, async () => {
    loading.value = true

    try {
      await lookupValueStore.deleteLookupType(lookupType.value._id)
      emit("submit")
    } finally {
      loading.value = false
    }
  }, "remove")
}
</script>